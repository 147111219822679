import React from "react"
import Layout from "../components/layout"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { css } from "@emotion/core"
import { rhythm } from "../utils/typography.js"
import logo from "./github_logo.png"

export default function ProjectPage({ data }) {
  return (
    <Layout>
      <div>
        {data.allMarkdownRemark.edges.map(({ node }) => (
          <div>
            <div>
              <span
                css={css`
                  margin: ${rhythm(1.5)};
                `}
              >
                <h4>
                  <Link target="_blank" to={node.frontmatter.permalink}>
                    {node.frontmatter.title}
                  </Link>
                </h4>
              </span>
              <div
                css={css`
                  margin-top: ${rhythm(0.5)};
                `}
              >
                <div
                  css={css`
                    padding-bottom: 1rem;
                  `}
                >
                  <b>{node.frontmatter.date} </b>
                </div>
                <div
                  css={css`
                    padding-bottom: 1rem;
                  `}
                >
                  {node.frontmatter.bio}
                </div>
                <b>Tech Used: </b>
                <i>{node.frontmatter.tech} </i> <br />
              </div>
              <Link target="_blank" to={node.frontmatter.github}>
                <img src={logo} alt="github logo" />
              </Link>
            </div>
          </div>
        ))}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { eq: "project" } } }
    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            permalink
            bio
            github
            tags
            tech
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
